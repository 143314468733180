export class SustainabilityInfo {
  socialCulturalAspect: SocialCulturalAspect;
  blueCenter: boolean;
  blueFlag: boolean;
  localDevelopment: LocalDevelopment;
  naturalSpaceProtection: boolean;
  blueTrail: boolean;
  certification: boolean;
  accessibility: boolean;
  tourismHealthy: boolean;

  constructor() {
    this.socialCulturalAspect = new SocialCulturalAspect();
    this.blueCenter = false;
    this.blueFlag = false;
    this.localDevelopment = new LocalDevelopment();
    this.naturalSpaceProtection = false;
    this.blueTrail = false;
    this.certification = false;
    this.accessibility = false;
    this.tourismHealthy = false;
  }

  static empty(): SustainabilityInfo {
    return new SustainabilityInfo();
  }
}

export class SocialCulturalAspect {
  socialCulturalAspect: boolean;
  monumentWithProtection: boolean;

  constructor() {
    this.socialCulturalAspect = false;
    this.monumentWithProtection = false;
  }
}

export class LocalDevelopment {
  internationalChain: boolean;
  localProperty: boolean;

  constructor() {
    this.internationalChain = false;
    this.localProperty = false;
  }
}
