import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClientService } from '../../../service/httpclient.service'
import { Router, ActivatedRoute } from "@angular/router";
import { TokenService } from '../../../service/token.service';
import { LanguageString } from '../../../models/languageString.model';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { SustainabilityInfo } from 'src/app/models/SustainabilityInfo';
const MAX_SIZE = 5 * 1024 * 1024; // 5 MB en bytes
const API_POINT_DEST = 'api/point/destination/'
const API_POINT = 'api/point/'
const API_ROUTE = 'api/route/'
const API_FILE = 'api/file/'
const API_TOPIC = 'api/topic/'
const API_TEMP_TOPIC = 'api/topic/temp/'
const API_DESTINATION = 'api/destination/'

@Component({
  selector: 'app-point-detail',
  templateUrl: './point-detail.component.html',
  styleUrls: ['./point-detail.component.scss']
})
export class PointDetailComponent implements OnInit, AfterViewInit {

  render!: any;
  base64!: any
  base64Audio!:any;
  avatarPresave!: any;
  destinationSelected;
  destinations = [];
  ownerDestinations = [];
  newTopic = { name: '', local: false };
  newTopics = [];
  tempTopics = [];
  topics = [];
  localTopics = [];
  otherFiles = [];
  routes = [];
  file: File;
  lang: LanguageString;
  destinationId;
  pointId;
  destination;
  image;
  otherImages = [];
  timeConstraints: [{ visibleSchedules: [{ lang: "en", s: "" }, { lang: "es", s: "" }] }];
  topicsFilter = [];
  currentUrl;
  sustainabilityInfo:SustainabilityInfo=new SustainabilityInfo();
  point = {
    id: null,
    audioguide: null,
    audioguideEn: null,
    destinations: [],
    topics: [],
    image: '',
    gpsLong:'',
    gpsLat:'',
    otherImages: [],
    names: [{ lang: "en", s: "" }, { lang: "es", s: "" }],
    descriptions: [{ lang: "en", s: "" }, { lang: "es", s: "" }],
    shortDescriptions: [{ lang: "en", s: "" }, { lang: "es", s: "" }],
    singularities: [{ lang: "en", s: "" }, { lang: "es", s: "" }],
    visibleSchedules: [{ lang: "en", s: "" }, { lang: "es", s: "" }],
    timeConstraints: [{ visibleSchedules: [{ lang: "en", s: "" }, { lang: "es", s: "" }] }],
    bookingURLs: [{ value: '', names: [{ lang: "en", s: "" }, { lang: "es", s: "" }] }],
    sustainabilityInfo:this.sustainabilityInfo,
  };

  constructor(private http: HttpClientService, private snackBar: MatSnackBar,
    private _sanitizer: DomSanitizer,
    private activeRoute: ActivatedRoute,
    public tokenService: TokenService,
    public router: Router) { }

  ngOnInit() {
    if (this.tokenService.isAdmin()) {
      this.findDestinations();
    }
    this.currentUrl = document.URL.replace(/#.*$/, "");

    this.destination = this.tokenService.getDestination();
    this.findTopics().subscribe(() => {
      this.activeRoute.paramMap.subscribe(params => {
        if (params.get('id') != null) {
          this.destinationId = params.get('id');
          this.point.destinations.push(this.destinationId);
        }
        if (params.get('idPoint') != null) {
          this.pointId = params.get('idPoint');
          this.findPoint();
          this.findTempTopics();
          this.findDestinationInfoByPointId();
        }
      });
    });
  }


  ngAfterViewInit(): void {
    // Desplaza la página hacia arriba después de que la vista se haya inicializado
    window.scrollTo(0, 0);
  }
  findPoint() {
    this.http.get(API_POINT + this.pointId).subscribe(result => {

      if (result.timeConstraints == null || result.timeConstraints.length == 0) {
        result.timeConstraints = [{ visibleSchedules: [{ lang: "en", s: "" }, { lang: "es", s: "" }] }];
      } else {
        result.timeConstraints.forEach(time => {
          if (time.visibleSchedules == null || time.visibleSchedules == "") {
            time.visibleSchedules = [{ lang: "en", s: "" }, { lang: "es", s: "" }];
          }
        })
      }
      if (result.shortDescriptions == null || result.shortDescriptions.length == 0) {
        result.shortDescriptions = [{ lang: "en", s: "" }, { lang: "es", s: "" }];
      }
      if (result.bookingURLs == null || result.bookingURLs.length == 0) {
        result.bookingURLs = [{ value: '', names: [{ lang: "en", s: "" }, { lang: "es", s: "" }] }];
      }
      if (result.visibleSchedule == null || result.visibleSchedule == "" || result.visibleSchedule.length == 0) {
        result.visibleSchedules = [{ lang: "en", s: "" }, { lang: "es", s: "" }];
      }
      console.log("punto", this.point)
      this.point = result;
      if(this.point.sustainabilityInfo!=null){
        this.sustainabilityInfo= this.point.sustainabilityInfo;
      }
      this.findImages();
      this.findAudio();
      this.setTopics();
      this.findRoutes();
    });
  }

  findAudio() {
    if (this.point.audioguide != null && this.point.audioguide != "") {
      this.http.getBase64(API_FILE + this.point.audioguide + '/image/download').subscribe(result => {
        this.point.audioguide = this._sanitizer.bypassSecurityTrustResourceUrl('data:audio/mp3;base64,' + result.message);
      });
    }

    if (this.point.audioguideEn != null && this.point.audioguideEn != "") {
      this.http.getBase64(API_FILE + this.point.audioguideEn + '/image/download').subscribe(result => {
        this.point.audioguideEn = this._sanitizer.bypassSecurityTrustResourceUrl('data:audio/mp3;base64,' + result.message);
      });
    }
  }

  findImages() {
    if (this.point.image != null && this.point.image != "") {
      this.http.getBase64(API_FILE + this.point.image + '/url').subscribe(result => {
        this.image = result.message;
      });
    }

    if (this.point.otherImages != null && this.point.otherImages.length > 0) {
      const observables = this.point.otherImages.map((img, index) =>
        this.http.getBase64(API_FILE + img + '/url').pipe(
          map(result => ({ index, url: result.message }))
        )
      );

      forkJoin(observables).subscribe(results => {
        results.forEach(({ index, url }) => {
          this.otherImages[index] = url;
        });
      });
    }

  }

  setTopics() {

    if (this.point.id != null) {
      if (this.point.topics != null && this.point.topics.length > 0) {
        this.topics.forEach(topic => {
          if (this.point.topics.includes(topic.parent.id)) {
            topic.value = true;
          }
          if (topic.kids.length > 0) {
            topic.kids.forEach(kid => {
              if (this.point.topics.includes(kid.topic.id)) {
                kid.value = true;
              }
            })
          }

        })
        this.localTopics.forEach(topic => {
          if (this.point.topics.includes(topic.topic.id)) {
            topic.value = true;
          }
        })
      }
    }
    console.log("despues de set", this.topics);
  }

  save(goBack) {
    if (this.point.gpsLat && typeof this.point.gpsLat === 'string') {
      this.point.gpsLat = this.point.gpsLat.replace(',', '.');
    }

    // Validar y corregir la longitud
    if (this.point.gpsLong && typeof this.point.gpsLong === 'string') {
      this. point.gpsLong = this.point.gpsLong.replace(',', '.');
    }
    this.http.post(API_POINT, this.point).subscribe(result => {
      this.snackBar.open('Guardado con éxito.', 'Cerrar', {
        duration: 3000,
      });
      if (goBack) {
        this.goBack();
      } else {
        this.findPoint()
      }
    });
  }

  goBack() {
    if (this.tokenService.isAdmin()) {
      this.activeRoute.queryParams.subscribe(queryParams => {
        this.router.navigate(['/admin/point/'], {
          queryParams: {
            status: queryParams['status'],
            name: queryParams['name'],
            topics: queryParams['topics'],
            destinations: queryParams['destinations']
          }
        });
      });
    } else {
      this.router.navigate(['/destination/detail/' + this.destinationId + '/point']);
    }
  }

  addTimeConstraint() {
    this.point.timeConstraints.push({ visibleSchedules: [{ lang: "en", s: "" }, { lang: "es", s: "" }] });
  }

  removeTimeConstraint(index: number) {
    this.point.timeConstraints.splice(index, 1);
  }

  onAudioSelected(event: any, lang) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const MAX_SIZE = 10 * 1024 * 1024; // Tamaño máximo de 10 MB para audioguías (puedes ajustarlo según tus necesidades)
  
      if (file.size > MAX_SIZE) {
        this.snackBar.open('El archivo es demasiado grande. Tamaño máximo permitido: 10 MB.', 'Cerrar', {
          duration: 3000,
        });
        return; // Detiene la ejecución si el archivo es demasiado grande
      }
  
      reader.onload = (e: ProgressEvent) => {
        const target = e.target as FileReader; // Aserción de tipo aquí
        this.base64Audio = target.result as string; // Guardar la audioguía en base64
        this.uploadAudioBase64(lang);
      };
      reader.readAsDataURL(file); // Leer el archivo de audio como base64
      event.target.value = '';
    }
  }
  
  async uploadAudioBase64(lang) {
    if (this.base64Audio != null) {
      let request = { audio: this.base64Audio, type: lang, pointId: this.point.id };
  
      return this.http.post(`${API_FILE}upload/audioguide`, request).pipe(
        catchError(error => {
          console.error('Audio upload failed', error.message);
          this.snackBar.open('Error al subir el archivo de audio', 'Cerrar', {
            duration: 3000,
          });
          return throwError(error);
        })
      ).subscribe(response => {
        console.log('Audio subido correctamente:', response);
        this.findPoint(); // Actualiza la información del punto después de subir el audio
      });
    }
  }
  

  onImageSelected(event: any, principal: boolean) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const MAX_SIZE = 5 * 1024 * 1024; // Tamaño máximo de 5 MB

      if (file.size > MAX_SIZE) {
        this.snackBar.open('El archivo es demasiado grande. Tamaño máximo permitido: 5 MB.', 'Cerrar', {
          duration: 3000,
        });
        return; // Detiene la ejecución si el archivo es demasiado grande
      }

      this.avatarPresave = file;
      reader.onload = async (e: ProgressEvent) => {
        const target = e.target as FileReader; // Aserción de tipo aquí
        const resizedBase64 = await this.resizeImage(target.result as string, 800, 800, 0.7); // Redimensionar y comprimir
        this.render = resizedBase64;
        this.base64 = resizedBase64;
        this.uploadBase64(principal);
      };
      reader.readAsDataURL(file);
      event.target.value = '';
    }
  }


  async uploadBase64(principal: boolean) {
    if (this.base64 != null) {
      let request = { image: this.base64, type: 'points', pointId: this.point.id, principal: principal }

      const formData = new FormData();
      formData.append("file", this.base64 as string);
      formData.append("type", "points");
      formData.append("pointId", this.point.id);
      formData.append("principal", principal.toString());

      return this.http.post(`${API_FILE}upload`, request).pipe(
        catchError(error => {
          console.error('File upload failed', error.message);
          this.snackBar.open('Error!', error, {
            duration: 3000,
          });
          return throwError(error);
        })
      ).subscribe(response => {
        console.log(response);
        this.findPoint();
      });
    }
  }


  resizeImage(base64Str: string, maxWidth: number, maxHeight: number, quality: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height = Math.round((maxWidth / width) * height);
          width = maxWidth;
        }

        if (height > maxHeight) {
          width = Math.round((maxHeight / height) * width);
          height = maxHeight;
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        resolve(canvas.toDataURL('image/jpeg', quality));
      };
      img.onerror = (error) => reject(error);
    });
  }

  deleteImage(url, index) {
    this.http.deleteById(API_FILE + url).subscribe(result => {
      if (index != null) {
        this.point.otherImages.splice(index, 1);
        this.otherImages.splice(index, 1);
      } else {
        this.image = null;
        this.point.image = null;
      }
      this.save(false);
    });
  }

  findTopics() {
    return this.http.get(API_TOPIC).pipe(
      concatMap(result => {
        this.localTopics = result.filter(topic => topic.local == true).map(topic => {
          return { topic: topic, value: false }
        });
        let topics = result;
        let parents = topics.filter(topic => topic.parentId == null && !topic.local)
          .map(topic => {
            return { parent: topic, value: false, kids: [] };
          });
        parents.forEach(topic => {
          topic.kids = result.filter(topic => topic.local == null || topic.local == false)
            .filter(topic => !topic.local)
            .filter(topicChill => topicChill.parentId == topic.parent.id)
            .map(topic => { return { topic: topic, value: false } });
        });

        this.topics = parents;
        return of(result); // Emit the result to continue the chain
      })
    );
  }


  findTempTopics() {
    if (this.tokenService.isAdmin()) {
      this.http.get(API_TEMP_TOPIC + this.pointId).subscribe(result => {
        this.tempTopics = result;
      })
    }
  }

  deleteTempTopics() {
    if (this.tokenService.isAdmin()) {
      let ids = this.tempTopics.map(tempTopic => tempTopic.id);
      let params = new HttpParams().set('topics', ids.toString())
      this.http.deleteAllIds(API_TEMP_TOPIC, params).subscribe(result => {
        console.debug(result);
      });
    }
  }

  saveAndDelete() {
    if (this.newTopics.length > 0) {
      this.newTopics.forEach(topic => {
        this.http.post(API_TOPIC, topic).subscribe(result => {
          console.info(result);

          this.point.topics.push(result.id);
          this.save(false);
          this.deleteTempTopics();
        });
      })
    }
  }

  topicCheck(object: any) {
    this.topicsSelected(object.event.checked, object.topic);
  }


  topicsSelected(event, topic) {
    if (event) {
      if (this.point.topics == null) {
        this.point.topics = [];
      }
      this.point.topics.push(topic);
    } else {
      this.point.topics = this.point.topics.filter(t => t != topic);
    }
    console.log(this.point.topics);
  }

  deleteTopicToList(index) {
    this.newTopics.splice(index, 1);
  }

  addTopicToNewTopics() {
    this.newTopics.push(this.newTopic);
    this.newTopic = { name: '', local: false };
  }

  findRoutes() {
    this.http.get(API_ROUTE + "point/" + this.pointId).subscribe(data => this.routes = data)
  }

  findDestinations() {
    this.http.get(API_DESTINATION).subscribe(result => {
      this.destinations = result;
    })
  }

  findDestinationInfoByPointId() {
    this.http.get(API_DESTINATION + "point/" + this.pointId).subscribe(result => {
      this.ownerDestinations = result;
      if (this.ownerDestinations.length > 0) {
        this.destination = this.ownerDestinations[0];
        this.destinationId = this.ownerDestinations[0].id;
      }
      let destTopicsId = [];
      this.ownerDestinations.forEach(des => destTopicsId = destTopicsId.concat(des.topics));

      this.localTopics = this.localTopics.filter(t => destTopicsId.includes(t.topic.id))
        .sort((a, b) => a.topic.name.localeCompare(b.topic.name));

    })
  }

  addDestinationOwner(destinationId) {
    let destination = this.destinations.find(dest => dest.id === destinationId);
    console.log(destination);
    this.point.destinations.push(destination.id);
    this.ownerDestinations.push({ name: destination.name, usersEmails: ['Sin guardar'] })
  }

  extractIdFromUrl(url) {
    const regex = /points\/([a-f0-9-]+)\?/;
    const matches = url.match(regex);
    if (matches && matches[1]) {
      return matches[1];
    }
    return null;
  }

  addVisibleSchedule(): void {
    // Aseguramos que la lista exista
    if (!this.point.visibleSchedules) {
      this.point.visibleSchedules = [];
    }
    // Insertamos un nuevo ítem vacío
    this.point.visibleSchedules.push({
      lang: '',
      s: ''
    });
  }
  removeVisibleSchedule(index: number): void {
    if (this.point.visibleSchedules) {
      this.point.visibleSchedules.splice(index, 1);
    }
  }

}
