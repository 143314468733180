import { Injectable } from '@angular/core';
import { HttpClientService } from './httpclient.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const API_TOPIC = 'api/topic/';

@Injectable({
  providedIn: 'root'
})
export class TopicService {
  public allTopics: any[] = [];
  public colorMap: Map<string, string> = new Map();

  constructor(private http: HttpClientService) { }

  // Método público que revisa si los temas ya están en caché
  public getAllTopics(): Observable<any[]> {
    if (this.allTopics.length > 0) {
      // Si ya tenemos temas en caché, devolvemos un Observable de esos datos
      return of(this.allTopics);
    } else {
      // Si no tenemos temas en caché, los buscamos y los almacenamos
      return this.findTopics().pipe(
        map(topics => {
          this.allTopics = topics;
          this.populateColorMap(topics);
          return topics;
        })
      );
    }
  }

  public async getAllTopicsAsync(): Promise<any[]> {
    // Si ya tenemos temas en caché, devolvemos los datos almacenados
    if (this.allTopics.length > 0) {
      return this.allTopics;
    } 
    
    // Si no tenemos temas en caché, los buscamos y los almacenamos
    const topics = await this.findTopics().toPromise();
    this.allTopics = topics;
    this.populateColorMap(topics);
    return topics;
  }


  private populateColorMap(topics: any[]): void {
    topics.forEach(topic => {
      if (topic.id && topic.colorInHex) {
        this.colorMap.set(topic.id, topic.colorInHex);
      }
    });
  }
  public serAllTopics(topics) {
    this.allTopics;
  }

  
  public getTopicsByIds(ids: string[]): Observable<any[]> {
    return this.findTopics().pipe(
      map(topics => topics.filter(topic => ids.includes(topic.id)))
      );
    }
    
    private findTopics(): Observable<any[any]> {
      return this.http.get(API_TOPIC);
    }

    public async findHexColorById(id: string): Promise<string | null> {
      console.log("findHexColorById", this.colorMap, id);
      if (this.colorMap.has(id)) {
        return this.colorMap.get(id) || null;
      }
      await this.getAllTopics().toPromise();
      return this.colorMap.get(id) || null;
    }
  

  public getGroupTopics(): Observable<any[]> {
    return this.getAllTopics().pipe(
      map(result => {
        let topics = result;
        let parents = topics.filter(topic => topic.parentId == null )
          .map(topic => {
            return { parent: topic, value: false, kids: [] as any[] };
          });

        parents.sort((a, b) => a.parent.name.localeCompare(b.parent.name));

        parents.forEach(topic => {
          topic.kids = topics.filter(topicChild => topicChild.parentId === topic.parent.id )
            .map(topicChild => ({ topic: topicChild, value: false }))
            .sort((a, b) => a.topic.name.localeCompare(b.topic.name)); // Ordenar subtemas
        });

        return parents; // Devolver los datos procesados
      })
    );
  }



}
