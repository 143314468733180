import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../../service/httpclient.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Platform } from 'src/app/models/Platform';
import { MatSnackBar } from '@angular/material';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PlatformNotificationStatus } from 'src/app/models/PlatformNotificationStatus';
import { PlatformService } from 'src/app/service/Platform.service';
import { Destination } from 'src/app/models/Destinations';
const API_DESTINATION = 'api/destination/';
const API_CLIENTS = 'api/user/client';
const API_POINT = 'api/point/destination/';
const API_TOPIC = 'api/topic/'
const API_FILE = 'api/file/'
@Component({
  selector: 'app-admin-destination-detail',
  templateUrl: './admin-destination-detail.component.html',
  styleUrls: ['./admin-destination-detail.component.scss']
})
export class AdminDestinationDetailComponent implements OnInit {
  platforms!: any;
  platformOptions = Object.values(Platform);
  selectedPlatforms: Set<String> = new Set<String>();
  platformNotificationStatus: PlatformNotificationStatus[];
  destination: any = { topics: [], name: "", names: [{ lang: "en", s: "" }, { lang: "es", s: "" }] };
  destinations = [];
  childs = [];
  destinationId;
  users;
  ownerUsers = [];
  nameEs;
  nameEn;
  clientId;
  points;
  topics = [];
  localTopics = [];
  topicsFilter = [];
  constructor(private router: Router,
    private platformService: PlatformService,
    private snackBar: MatSnackBar,
    private activeRoute: ActivatedRoute,
    private http: HttpClientService
  ) { }

  ngOnInit() {
    this.platformService.platforms$.subscribe({
      next: (platforms) => {
        this.platformOptions = platforms;
        this.platforms = this.platformOptions;
        console.log("Platforms!" + platforms);
        this.initializePlatformStatuses();
      },
      error: (error) => {
        console.error('Failed to load platforms', error);
      }
    });
    this.findAllDestinations();

    this.activeRoute.paramMap.subscribe(params => {
      if (params.get('id') != null) {
        this.destinationId = params.get('id');
        this.findUsers();
        this.findPoints();
      }
    });
  }

  initializePlatformStatuses() {
    this.platformNotificationStatus = this.platforms.map(platform => ({
      platform: platform,
      enabledNotifications: false
    }));
    console.log("initializePlatformStatuses!" + this.platformNotificationStatus);
  }

  toggleNotification(platform: Platform) {
    const status = this.platformNotificationStatus.find(p => p.platform === platform);
    if (status) {
      status.enabledNotifications = !status.enabledNotifications;
      // this.statusChange.emit(this.platformNotificationStatus);
    }
    console.log(this.platformNotificationStatus)
  }

  findDestination() {
    this.http.get(API_DESTINATION + this.destinationId).subscribe(
      response => {
        this.selectedPlatforms = new Set<String>(response.platforms.map(p => p.name));

        this.updatePlatformStatus(response.platformNotificationStatus);

        this.destination = response;
        this.addUserToList();
        this.findTopics();
      }
    );
  }

  // updatePlatformStatus(responseStatuses: any[]): void {
  //   const platformStatusMap = new Map(responseStatuses.map(s => [s.platform.name, s.enabledNotifications]));

  //   this.platformNotificationStatus = this.platformNotificationStatus.map(status => ({
  //     platform: status.platform,
  //     enabledNotifications: platformStatusMap.get(status.platform.name) != null ? platformStatusMap.get(status.platform.name) : false
  //   }));
  // }

  updatePlatformStatus(responseStatuses: any[]): void {
    if (!responseStatuses || responseStatuses.length === 0) {
      this.platformNotificationStatus = this.platformNotificationStatus.map(status => ({
        platform: status.platform,
        enabledNotifications: false
      }));
      return;
    }

    const platformStatusMap = new Map(responseStatuses.map(s => [s.platform.name, s.enabledNotifications]));

    this.platformNotificationStatus = this.platformNotificationStatus.map(status => ({
      platform: status.platform,
      enabledNotifications: platformStatusMap.get(status.platform.name) || false
    }));
  }


  onCheckboxChange(platform: Platform, isChecked: boolean) {
    if (isChecked) {
      this.selectedPlatforms.add(platform.name);
    } else {
      this.selectedPlatforms.delete(platform.name);
    }
  }



  isPlatformSelected(platform: Platform): boolean {
    return this.selectedPlatforms.has(platform.name);
  }

  findPoints() {
    this.http.get(API_POINT + this.destinationId).subscribe(
      response => {
        this.points = response.filter(p => p.name !== "ini");
        let originPoints = response.filter(p => p.id === this.destination.originPoint)
        if (originPoints != null && originPoints.length > 0) {
          let originPoint = originPoints[0];

        }
        this.points = this.points.sort((a, b) => {
          if (a.id === this.destination.originPoint) return -1;
          if (b.id === this.destination.originPoint) return 1;
          return 0;
        });
      }
    );
  }

  addUserToList() {
    for (let userId of this.destination.users) {
      let user = this.users.find(u => u.id === userId);
      if (user) {
        if (!this.ownerUsers.some(ou => ou.id === user.id)) {
          this.ownerUsers.push({ id: user.id, name: user.name, email: user.email });
        }
      }
    }
    console.log("  this.ownerUsers",   this.ownerUsers);
  }

  findUsers() {
    this.http.get(API_CLIENTS).subscribe(
      response => {
        this.users = response;
        this.findDestination();
      }
    );
  }

  findAllDestinations() {
    this.http.get(API_DESTINATION).subscribe(
      response => {
        this.destinations = response;
        this.findChildsDestinations();

      }
    );
  }

  findChildsDestinations() {
    if (this.destinationId != null && this.destinations.length > 0) {
      this.childs = this.destinations.filter(des => des.parentId === this.destinationId);
    }

  }

  save() {
    this.destination.platforms = this.platforms.filter(p => this.selectedPlatforms.has(p.name)).map(p => p.name);
    this.destination.platformNotificationStatus = this.platformNotificationStatus;
    this.destination.topics = this.topicsFilter;
    this.http.post(API_DESTINATION, this.destination).subscribe(
      response => {
        this.snackBar.open('Guardado con éxito.', 'Cerrar', {
          duration: 3000,
        });
        this.goBack();
      }
    );
  }

  addClient() {
    if (this.clientId != null) {
      if (this.destination.users != null && this.destination.users.length > 0) {
        if (!this.destination.users.includes(this.clientId)) {
          this.destination.users.push(this.clientId);
          this.clientId = null;
          this.addUserToList();
        }
      }
    }
  }

  goBack() {
    this.router.navigate(['/admin/destination/']);
  }

  findTopics() {
    this.http.get(API_TOPIC).subscribe(result => {
      this.localTopics = result.filter(topic => topic.local == true).map(topic => {
        return { topic: topic, value: this.destination.topics.includes(topic.id) }
      }).sort((a, b) => a.topic.name.localeCompare(b.topic.name));
      

      this.topics = result;
      this.topicsFilter = this.destination.topics;
    
      console.log(this.destination);
    })
  }



  topicsSelected(event: any, topic) {
    if (event.checked) {
      if (this.topicsFilter == null) {
        this.topicsFilter = [];
      }
      this.topicsFilter.push(topic);
    } else {
      this.topicsFilter = this.topicsFilter.filter(t => t != topic);
    }
    console.log(this.topicsFilter);
  }

  deleteImage(id, index) {
    // this.http.deleteById(API_FILE + id).subscribe(result => {
    //   if (index != null) {
    //     this.point.otherImages.splice(index, 1);
    //   } else {
    //     this.image = null;
    //   }
    //   this.save(false);
    // });
  }

  avatarPresave;
  render;
  base64;
  onImageSelected(event: any, principal: boolean) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      const MAX_SIZE = 5 * 1024 * 1024; // Tamaño máximo de 5 MB

      if (file.size > MAX_SIZE) {
        this.snackBar.open('El archivo es demasiado grande. Tamaño máximo permitido: 5 MB.', 'Cerrar', {
          duration: 3000,
        });
        return; // Detiene la ejecución si el archivo es demasiado grande
      }

      this.avatarPresave = file;
      reader.onload = (e: ProgressEvent) => {
        const target = e.target as FileReader; // Aserción de tipo aquí
        this.render = target.result as string;
        this.base64 = target.result as string;
        this.uploadBase64(principal);
      };
      reader.readAsDataURL(file);
    }
  }

  uploadBase64(principal: boolean) {
    if (this.base64 != null) {
      let request = { image: this.base64, type: 'destination', destinationId: this.destinationId, principal: principal }
      return this.http.postImg(`${API_FILE}upload/destination`, request).pipe(
        catchError(error => {
          console.error('File upload failed', error);
          this.snackBar.open('Error!', error, {
            duration: 3000,
          });
          return throwError(error);
        })).subscribe(response => {
          // this.destination();
        });
    }
  }

  deleteClient(user) {
    this.http.deleteAll(`${API_DESTINATION}${this.destinationId}/user/${user.id}`).subscribe(result => {
      this.ownerUsers = this.ownerUsers.filter(u => u.id !== user.id);
    })
  }

}
