import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../../service/httpclient.service';
import { Router, ActivatedRoute } from "@angular/router";
import { PlatformService } from 'src/app/service/Platform.service';
import { Platform } from 'src/app/models/Platform';

interface LanguageString {
  lang: string;
  s: string;
}

interface Topic {
  id?: string;
  name: string;
  names: LanguageString[];
  colorInHex: string;
  parentId?: string;
  local: boolean;
  priceRange: any;
  averageDuration: number;
  platforms: any[];
}

const API_TOPIC = 'api/topic/';

@Component({
  selector: 'app-admin-topic-detail',
  templateUrl: './admin-topic-detail.component.html',
  styleUrls: ['./admin-topic-detail.component.scss']
})
export class AdminTopicDetailComponent implements OnInit {
  topic: Topic = {
    name: '',
    names: [
      { lang: 'en', s: '' },
      { lang: 'es', s: '' }
    ],
    colorInHex: '',
    local: false,
    priceRange: '',
    averageDuration: 0,
    platforms: []
  };
  topics: Topic[] = [];
  topicId: string | null = null;
  platforms: Platform[] = [];
  platformOptions: any[] = [];
  selectedPlatforms: Set<String> = new Set<String>();

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private platformService: PlatformService,
    private httpClientService: HttpClientService
  ) {}

  ngOnInit() {
    // Load available platforms
    this.platformService.platforms$.subscribe({
      next: (platforms) => {
        this.platformOptions = platforms;
        this.platforms = platforms;
        console.log('Platforms loaded:', platforms);
      },
      error: (error) => {
        console.error('Failed to load platforms:', error);
      }
    });

    // Load all topics for parent selection
    this.findAllTopics();

    // Check if editing an existing topic
    this.activeRoute.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.topicId = id;
        this.findTopic();
      }
    });
  }

  findAllTopics() {
    this.httpClientService.get(API_TOPIC).subscribe({
      next: (response: Topic[]) => {
        this.topics = response.filter(topic => topic.parentId == null);
      },
      error: (error) => {
        console.error('Failed to load topics:', error);
      }
    });
  }

  findTopic() {
    if (!this.topicId) return;
  
    this.httpClientService.get(`${API_TOPIC}${this.topicId}`).subscribe({
      next: (response: Topic) => {
        this.topic = response;
        this.selectedPlatforms = new Set<String>(response.platforms.map(p => p.name));
      },
      error: (error) => {
        console.error('Failed to load topic:', error);
      }
    });
  }
  

  onCheckboxChange(platform: Platform, checked: boolean) {
    if (checked) {
      this.selectedPlatforms.add(platform.name);
    } else {
      this.selectedPlatforms.delete(platform.name);
    }
    this.topic.platforms = this.platforms.filter(p => this.selectedPlatforms.has(p.name)).map(p => p.name);

  }

  save() {
    if (typeof this.topic.priceRange === 'string') {
      this.topic.priceRange=this.topic.priceRange.split(",");
    }

    this.httpClientService.post(API_TOPIC, this.topic).subscribe({
      next: () => {
        this.goBack();
      },
      error: (error) => {
        console.error('Failed to save topic:', error);
      }
    });
  }

  isChecked(platform){
    console.log(platform, this.selectedPlatforms.has(platform))
    return this.selectedPlatforms.has(platform.name);
  }
  goBack() {
    this.router.navigate(['/admin/topic/']);
  }
}