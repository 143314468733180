import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, delay } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { HttpClientService } from '../../service/httpclient.service'
import { MatSort, Sort } from '@angular/material/sort';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { TopicService } from 'src/app/service/topic.service';
import { Platform } from 'src/app/models/Platform';
import { PlatformService } from 'src/app/service/Platform.service';

const API_TOPIC = 'api/topic/';
@Component({
  selector: 'app-admin-topic',
  templateUrl: './admin-topic.component.html',
  styleUrls: ['./admin-topic.component.scss']
})
export class AdminTopicComponent implements OnInit {
  platforms!: any;
  platformOptions = Object.values(Platform);
  selectedPlatforms: Set<String> = new Set<String>();

  dataSource: Array<any> = [];
  filteredData: Array<any> = [];
  resultsLength = 200;
  displayedColumns: string[] = ['name', 'parent', 'edit', 'remove'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  empty = false;
  nameSearch = '';
  typeFilter = "";
  constructor(
    private http: HttpClientService, public dialog: MatDialog, private platformService: PlatformService, private topicService: TopicService
  ) { }
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit() {
    this.platformService.platforms$.subscribe({
      next: (platforms) => {
        this.platformOptions = platforms;
        this.platforms = this.platformOptions;
        console.log("Platforms!" + platforms);
      },
      error: (error) => {
        console.error('Failed to load platforms', error);
      }
    });
    this.find(false);
  }



  onCheckboxChange(platform: Platform, isChecked: boolean) {
    if (isChecked) {
      this.selectedPlatforms.add(platform.name);
    } else {
      this.selectedPlatforms.delete(platform.name);
    }
  }

  filterByName() {
    this.filteredData = this.dataSource.filter(parent =>
      parent.parent.name.toLowerCase().includes(this.nameSearch.toLowerCase()) ||
      parent.kids.some(kid => kid.topic.name.toLowerCase().includes(this.nameSearch.toLowerCase()))
    );
  }

  filterTopics() {
    // 1) Filtro intermedio por 'typeFilter'
    let intermediate = this.dataSource.filter((parent: any) =>
      this.matchTypeFilter(parent, this.typeFilter)
    );

    // 2) Filtro final por 'selectedPlatforms'
    intermediate = intermediate.filter((parent: any) =>
      this.matchPlatformFilter(parent, this.selectedPlatforms)
    );

    this.filteredData = intermediate;
  }

  // Devuelve true si el 'parent' (o alguno de sus kids) coincide con 'typeFilter'
  private matchTypeFilter(parent: any, typeFilter: string): boolean {
    if (typeFilter === "") {
      return true; // no filtra nada
    } else if (typeFilter === "Locales") {
      return (
        parent.parent.local === true ||
        parent.kids.some((kid: any) => kid.topic.local === true)
      );
    } else if (typeFilter === "Generales") {
      return (
        (parent.parent.local == null || parent.parent.local === false) ||
        parent.kids.some((kid: any) => kid.topic.local == null || kid.topic.local === false)
      );
    }
    return true; // por si hay otro caso
  }

  /**
   * Devuelve true si el 'parent' (o alguno de sus kids) coincide 
   * con las 'selectedPlatforms'. 
   * Ajusta la propiedad donde se guarda la plataforma 
   * (ej: 'platform' u otra).
   */
  private matchPlatformFilter(parent: any, selectedPlatforms: any): boolean {
    if (!selectedPlatforms || selectedPlatforms.size === 0) {
      return true;
    }
  
    // Verificar plataformas del padre
    let parentPlatforms = [];
    if (parent && parent.parent && Array.isArray(parent.parent.platforms)) {
      parentPlatforms = parent.parent.platforms;
    }
    const parentMatch = parentPlatforms.some((pf: any) =>
      pf && pf.name && selectedPlatforms.has(pf.name)
    );
  
    // Verificar las de los hijos
    let kidsMatch = false;
    if (parent && Array.isArray(parent.kids)) {
      kidsMatch = parent.kids.some((kid: any) => {
        let kidPlatforms = [];
        if (kid && kid.topic && Array.isArray(kid.topic.platforms)) {
          kidPlatforms = kid.topic.platforms;
        }
        return kidPlatforms.some((pf: any) =>
          pf && pf.name && selectedPlatforms.has(pf.name)
        );
      });
    }
  
    return parentMatch || kidsMatch;
  }
  
  


  find(sort) {
    this.topicService.allTopics = [];
    this.topicService.getGroupTopics().subscribe(topics => {
      console.log(topics);
      this.dataSource = topics;
      this.filteredData = topics;
    });
    // merge(this.paginator.page)
    //   .pipe(
    //     startWith({}),
    //     delay(0),
    //     switchMap(() => {
    //       let params;
    //       if (sort == true) {
    //         params = new HttpParams()
    //           .set('sort', this.sort.active + "," + this.sort.direction)
    //           .set('pageIndex', this.paginator.pageIndex.toString()).set('pageSize', this.paginator.pageSize.toString());
    //       } else {
    //         params = new HttpParams()
    //           .set('pageIndex', this.paginator.pageIndex.toString()).set('pageSize', this.paginator.pageSize.toString());
    //       }
    //       if(this.nameSearch !=null){
    //         params = params.set('name', this.nameSearch);
    //       }
    //       return this.http.get(API_TOPIC + 'search', params);
    //     }),
    //     map(data => {
    //       console.log(data);
    //       this.resultsLength = data.totalElements;
    //       return data;
    //     }),
    //     catchError(() => {
    //       return observableOf([]);
    //     })
    //     ).subscribe(data => {
    //       console.log(data.content);
    //       this.dataSource = data.content;
    //       this.dataSource.map(t=>{
    //         if(t.parentId!=null && t.parentId!="-1"){
    //           t.parentId = this.dataSource.filter(tp=>tp.id===t.parentId)[0].name;
    //         }else if(t.parentId=="-1"){
    //           t.parentId =null;
    //         }
    //       })
    //       if(this.dataSource.length == 0){
    //         this.empty = true;
    //       }else{
    //         this.empty = false;
    //       }
    //   });
  }
  toggleCollapse(parent) {
    parent.collapsed = !parent.collapsed;
  }
  remove(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { id: id, type: 'topic', title: 'Eliminar categoría', text: '¿Quieres eliminar esta categoría permanentemente?', mainButton: 'Eliminar' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        this.find(false);
      }
    });
  }

  announceSortChange(sortState: Sort) {
    this.find(true);

  }

}
